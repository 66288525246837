import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { AuthProvider } from './context/AuthContext';

ReactDOM.render(
    // basename="/web"
    <Router >
        <AuthProvider dir="rtl">
            <App />
        </AuthProvider>
    </Router>, document.getElementById('root')
);
