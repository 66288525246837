
var config = {

    // Set the Demo Version
    demo: false,

    //SEO Configurations
    appName: "Alefredo Quizes",
    metaDescription: "Alefredo Quizes is a Web Quiz Application",
    metaKeyWords: "Alefredo, Quizes,Quiz,Questions,Answers,Online Quiz",

    //API Configurations
    apiAccessKey: 8525,
    apiUrl: "https://alefredoquizzes.com/admin/api/",

    //Language Configurations
    // Get Your Language Codes ---> https://developers.google.com/admin-sdk/directory/v1/languages
    supportedLanguages:["en"],
    defaultLanguage: "en",

    // If your Default Language is not in supportedLanguages then add there first and after that set the defaultLanguage.

    //Quiz Configurations
    questionTimerSeconds: 15,
    levelWinCheckPoint: 30,         // Above 30% is required to Clear the Quiz Level
    maxWinningCoins:4,              // This will give user the coins if user will clear the level
    deductReviewAnswerCoins: 10,    // 10 coins will be deducted if user Review the Answer
    addCorrectAnswerScore:2,        // This will increase the user score after each correct answer
    deductIncorrectAnswerScore:1,   // This will deduct the points if user will give wrong answer
    deductLifeLineCoins:1,          // Deduct Coins when using Life Line

    DefaultCountrySelectedInMobile: "in",   //Default Country Selected in Mobile Login Screen

    //Firebase Configurations
    apiKey: "AIzaSyCn9R58xiwDddksOLPGgfaq_vvVCkgnglo",
    authDomain: "alefredoquizzes.firebaseapp.com",
    projectId: "alefredoquizzes",
    storageBucket: "alefredoquizzes.appspot.com",
    messagingSenderId: "28505373711",
    appId: "1:28505373711:web:4fe7946f22c90b9e61377a",
    measurementId: "G-DE4VH6XXJQ",


    //footer area
    companytext: "Alefredo Quizzes is an educational platform that offers students an interactive assessment tool and mock exams that highlights their weaknesses and recommends supporting material to strengthen them. currently focusing on school International Students (AP IGCSE ACT IB SAT Edexcel, Checkpoints and more). and it is one of Alefredo Books Company products.",
    addresstext: "Address: info@alefredobooks.com JOR \n +962 78 6 49 49 49 \n UAE +971 52 6 57 38 00",
    phonenumber: "JOR +962 786 49 49 49 \n UAE +971 526 57 38 00",
    email:"info@alefredobooks.com",
    facebooklink: "https://www.facebook.com/Alefredobooks/",
    instagramlink: "https://www.instagram.com/invites/contact/?i=1keuvp1vihr70&utm_content=4g896vu",
    linkedinlink: "https://www.linkedin.com/in/alefredo-books-8209b5177",
    weblink: "http://alefredoquizzes.com/",
    companyname:"Alefredo Books"
};

export default config;